import "./index.scss";

/**
 * Import modules
 */
import "toaststrap/dist/toaststrap.js";
import Inputmask from "inputmask/dist/inputmask.min.js";
import {Fancybox} from "@fancyapps/ui";
import Swiper from "swiper/bundle";
import ymaps from "ymaps";
import lozad from "lozad";


/**
 * Helpers
 */
const cyrValue = str => {
    return /[^а-яА-ЯёЁ -]/ig.test(str);
}
const fancyboxShow = (src, type) => {
    Fancybox.show([
        {
            src: src,
            type: type,
        },
    ])
}
const toast = (title, text, type) => {
    toaststrap({
        title: title,
        text: text,
        position: "BOTTOM_END",
        type: type,
        duration: 5,
        pausable: true,
        progress: true,
        space: 20
    }).show()
}


/**
 * Fields validation
 */
if (document.querySelector("input[name=\"agreement\"]")) {
    document.querySelectorAll("input[name=\"agreement\"]").forEach(el => {
        el.addEventListener("change", evt => {
            const status = evt.currentTarget.checked;

            if (status) {
                evt.currentTarget.closest("form").querySelector("input[type=\"submit\"]").removeAttribute("disabled");
                evt.currentTarget.closest("form").querySelector(".form__submit").classList.remove("disabled");
            } else {
                evt.target.closest("form").querySelector("input[type=\"submit\"]").setAttribute("disabled", "");
                evt.currentTarget.closest("form").querySelector(".form__submit").classList.add("disabled");
            }
        })
    })
}
if (document.querySelectorAll("input[name=\"name\"]")) {
    document.querySelectorAll("input[name=\"name\"]").forEach(el => {
        let counter = 0;

        el.addEventListener("keyup", evt => {
            if (cyrValue(evt.target.value)) {
                counter++;

                if (counter > 5) {
                    toast("Внимание!", "Имя должно быть на русском языке", "WARNING");
                    counter = 0;
                }
            }

            evt.target.value = evt.target.value.replace(/[^а-яА-ЯёЁ -]/ig, "");
        })
    })
}
if (document.querySelector("input[name=\"phone\"]")) {
    document.querySelectorAll("input[name=\"phone\"]").forEach(el => {
        let im = new Inputmask({
            mask: "+7 (e99) 999-99-99",
            definitions: {
                "e": {
                    validator: "[0-7,9]"
                }
            }
        });
        im.mask(el);

        el.addEventListener("blur", evt => {
            if ((evt.currentTarget.value[17] == "_") || (evt.currentTarget.value[17] == undefined)) {
                toast("Внимание!", "Некорректный номер", "WARNING");
                evt.target.parentNode.classList.add("error");
                evt.target.parentNode.classList.remove("valid");
            } else {
                evt.target.parentNode.classList.add("valid");
                evt.target.parentNode.classList.remove("error");
            }
        })
    })
}
if (document.querySelector("input[required]")) {
    document.querySelectorAll("input[required]").forEach(el => {
        el.addEventListener("blur", evt => {
            if (evt.target.value.length == 0) {
                toast("Внимание!", "Поле обязательно для заполнения", "WARNING");
            }
        })
    })
}
if (document.querySelector("input[type=\"text\"], input[type=\"email\"], textarea")) {
    document.querySelectorAll("input[type=\"text\"], input[type=\"email\"], textarea").forEach(el => {
        el.addEventListener("blur", evt => {
            if (evt.target.value.length > 0) {
                evt.target.parentNode.classList.add("valid")
            } else {
                evt.target.parentNode.classList.remove("valid")
            }
        })
    })
}
if (document.querySelector("input[name=\"file_attach[]\"]")) {
    document.querySelectorAll("input[name=\"file_attach[]\"]").forEach(el => {
        el.addEventListener("change", evt => {
            let totalSize = 0;
            Object.keys(e.currentTarget.files).map((objectKey, index) => {
                totalSize = totalSize + evt.currentTarget.files[index].size;
            })
            if ((Math.round((totalSize / 1048576) * 100) / 100) > 10) {
                el.value = "";
                toast("Внимание!", "Максимальный объём вложений - 10 мб.", "WARNING");
            } else if (evt.currentTarget.files.length > 10) {
                el.value = "";
                toast("Внимание!", "Максимальное количество вложений - 10 шт.", "WARNING");
            } else {
                evt.currentTarget.closest("form").querySelector("#file-name").innerText = evt.currentTarget.files.length + " шт. (" + (Math.round((totalSize / 1048576) * 100) / 100) + " мб.)";
                evt.currentTarget.closest("form").querySelector("p.filename").classList.add("is-loaded");
            }
        })
        el.closest("form").querySelector("#file-clear").addEventListener("click", evt => {
            el.value = "";
            evt.currentTarget.closest("form").querySelector("p.filename").classList.remove("is-loaded");
            document.querySelector("#file-name").innerText = "";
        })
    })
}


/**
 * Form
 */
if (document.querySelector("form.fetch")) {
    document.querySelectorAll("form.fetch").forEach(form => {
        form.addEventListener("submit", evt => {
            evt.preventDefault();
            let yaGoal = form.querySelector("input[name=\"ya_goal\"]").value,
                phone = form.querySelector("input[name=\"phone\"]").value;

            if ((phone.length[17] == "_") || (phone.length == 0)) {
                document.querySelector("input[name=\"phone\"]").classList.add("is-error");
            } else {
                fancyboxShow("#loading", "inline");
                if (form.querySelector("input[type=\"submit\"]")) form.querySelector("input[type=\"submit\"]").setAttribute("disabled", "");
                if (form.querySelector("button[type=\"submit\"]")) form.querySelector("button[type=\"submit\"]").setAttribute("disabled", "");

                let data = new FormData(form);
                leadgets("lead", data, r => {
                    if (r.status == 1) {
                        Fancybox.close();

                        if (form.querySelector("input[type=\"submit\"]")) form.querySelector("input[type=\"submit\"]").removeAttribute("disabled", "");
                        if (form.querySelector("button[type=\"submit\"]")) form.querySelector("button[type=\"submit\"]").removeAttribute("disabled", "");

                        form.querySelectorAll("input").forEach(inp => {
                            if ((inp.type !== "submit") && (inp.type !== "checkbox") && (inp.type !== "radio") && (inp.type !== "hidden")) {
                                inp.value = "";
                                inp.parentNode.classList.remove("valid");
                            }
                        })
                        if (form.querySelector("textarea")) {
                            form.querySelector("textarea").value = "";
                            form.querySelector("textarea").parentNode.classList.remove("valid");
                        }
                        if (form.querySelector("input[type=\"checkbox\"]")) {
                            form.querySelectorAll("input[type=\"checkbox\"]").forEach(el => {
                                if (el.name != "agreement") el.checked = false;
                            })
                        }

                        setTimeout(() => {
                            fancyboxShow("#thanks", "inline");
                        }, 150);
                        setTimeout(() => {
                            Fancybox.close();
                        }, 5000);

                        if (typeof (ym) == "function") {
                            ym("XXXXXXXX", "reachGoal", yaGoal);
                            console.log("Цель достигнута: " + yaGoal);
                        }
                        if (typeof (gtag) == "function") {
                            gtag("event", "form_lead", {"event_category": "lead", "event_action": "zayavka"});
                        }
                    } else {
                        toast("Внимание!", "Ошибка! " + r.message, "WARNING");

                        Fancybox.close();
                        form.querySelector("input[type=\"submit\"]").removeAttribute("disabled", "");
                    }
                })
            }
        })
    })
}


/**
 * Mobile menu
 */
if (document.querySelector("button.hamburger")) {
    document.querySelector("button.hamburger").addEventListener("click", evt => {
        let buttonClasslist = evt.currentTarget.classList;

        buttonClasslist.toggle("active");
        if (buttonClasslist.contains("active")) {
            document.querySelector(".header__mobile").classList.add("active");
            document.querySelector(".header__mask").classList.add("active");
        } else {
            document.querySelector(".header__mobile").classList.remove("active");
            document.querySelector(".header__mask").classList.remove("active");
        }
    })
    document.querySelector(".header__mask").addEventListener("click", () => {
        document.querySelector("button.hamburger").classList.remove("active");
        document.querySelector(".header__mobile").classList.remove("active");
        document.querySelector(".header__mask").classList.remove("active");
    })
}
if (document.querySelector(".header__mobile .menu-item-has-children")) {
    document.querySelectorAll(".header__mobile .menu-item-has-children").forEach(el => {
        el.querySelector("a").addEventListener("click", evt => evt.preventDefault());

        el.addEventListener("click", evt => {
            el.classList.toggle("active");
        })
    })
}


/**
 * Modal
 */
if (document.querySelector(".modal-link")) {
    document.querySelectorAll(".modal-link").forEach(el => {
        el.addEventListener("click", evt => {
            evt.preventDefault();

            const button = evt.currentTarget,
                target = button.hash || button.dataset.href,
                popup = document.querySelector(target),
                title = button.dataset.modalTitle,
                content = button.dataset.modalContent,
                footer = button.dataset.modalFooter,
                submit = button.dataset.modalSubmit,
                yagoal = button.dataset.modalYagoal,
                email = button.dataset.modalEmail;

            if (title) popup.querySelector(".modal__title span").innerHTML = title;
            if (submit) popup.querySelector("input[type=\"submit\"]").value = submit;
            if (yagoal) popup.querySelector("input[name=\"ya_goal\"]").value = yagoal;
            if (email) popup.querySelector("input[name=\"email_title\"]").value = email;
            if (target) fancyboxShow(target, "inline");
        })
    })
}


/**
 * Lazy load
 */
const observer = lozad('.lozad', {
    loaded: function (el) {

        setTimeout(function () {
            var parent = el.parentNode;
            if (parent.tagName == 'PICTURE')
                parent = parent.parentNode;
            parent.className = parent.className.replace(/\bloading\b/, '');
        }, 200);

    }
});
/*const observer = lozad();*/
observer.observe();


/**
 * Sliders
 */
if (document.querySelector(".swiper-container.main-news")) {
    let commonServicesSwiper = new Swiper(".swiper-container.main-news", {
        loop: true,
        lazy: {
            loadPrevNext: true,
        },
        navigation: {
            nextEl: ".swiper-button-next.main-news",
            prevEl: ".swiper-button-prev.main-news",
        },
        pagination: {
            clickable: true,
            el: ".swiper-pagination.main-news",
        },
        preloadImages: true,
        slidesPerView: 1,
        spaceBetween: 30
    });
}
;
if (document.querySelector(".swiper-container.common-certificates-gallery")) {
    let commonServicesSwiper = new Swiper(".swiper-container.common-certificates-gallery", {
        breakpoints: {
            320: {
                allowTouchMove: true,
                slidesPerView: 1
            },
            415: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 3
            },
            1199: {
                allowTouchMove: false,
                slidesPerView: 3
            }
        },
        loop: false,
        lazy: {
            loadPrevNext: true,
        },
        navigation: {
            nextEl: ".swiper-button-next.common-certificates-gallery",
            prevEl: ".swiper-button-prev.common-certificates-gallery",
        },
        pagination: {
            clickable: true,
            el: ".swiper-pagination.common-certificates-gallery",
        },
        preloadImages: true,
        spaceBetween: 30
    });
}
;
if (document.querySelector(".swiper-container.news-gallery")) {
    let commonServicesSwiper = new Swiper(".swiper-container.news-gallery", {
        breakpoints: {
            320: {
                allowTouchMove: true,
                slidesPerView: 1
            },
            415: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 3
            },
            1199: {
                allowTouchMove: false,
                slidesPerView: 3
            }
        },
        loop: false,
        lazy: {
            loadPrevNext: true,
        },
        navigation: {
            nextEl: ".swiper-button-next.news-gallery",
            prevEl: ".swiper-button-prev.news-gallery",
        },
        pagination: {
            clickable: true,
            el: ".swiper-pagination.news-gallery",
        },
        preloadImages: true,
        spaceBetween: 30
    });
}
;
if (document.querySelector(".swiper-container.service-certificates")) {
    let commonServicesSwiper = new Swiper(".swiper-container.service-certificates", {
        breakpoints: {
            320: {
                allowTouchMove: true,
                slidesPerView: 1
            },
            415: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 3
            },
            1199: {
                allowTouchMove: false,
                slidesPerView: 1
            }
        },
        loop: false,
        lazy: {
            loadPrevNext: true,
        },
        navigation: {
            nextEl: ".swiper-button-next.service-certificates",
            prevEl: ".swiper-button-prev.service-certificates",
        },
        pagination: {
            clickable: true,
            el: ".swiper-pagination.service-certificates",
        },
        preloadImages: true,
        spaceBetween: 30
    });
}
;
if (document.querySelector(".swiper-container.equipment-spheres")) {
    let commonServicesSwiper = new Swiper(".swiper-container.equipment-spheres", {
        breakpoints: {
            320: {
                allowTouchMove: true,
                slidesPerView: 1
            },
            415: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 3
            },
            1199: {
                allowTouchMove: false,
                slidesPerView: 3
            }
        },
        loop: false,
        lazy: {
            loadPrevNext: true,
        },
        navigation: {
            nextEl: ".swiper-button-next.equipment-spheres",
            prevEl: ".swiper-button-prev.equipment-spheres",
        },
        pagination: {
            clickable: true,
            el: ".swiper-pagination.equipment-spheres",
        },
        preloadImages: true,
        spaceBetween: 30
    });
}
;
if (document.querySelector(".swiper-container.catalog-equipment")) {
    let commonServicesSwiper = new Swiper(".swiper-container.catalog-equipment", {
        breakpoints: {
            320: {
                allowTouchMove: true,
                slidesPerView: 1
            },
            415: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 3
            },
            1199: {
                allowTouchMove: false,
                slidesPerView: 3
            }
        },
        loop: false,
        lazy: {
            loadPrevNext: true,
        },
        navigation: {
            nextEl: ".swiper-button-next.catalog-equipment",
            prevEl: ".swiper-button-prev.catalog-equipment",
        },
        pagination: {
            clickable: true,
            el: ".swiper-pagination.catalog-equipment",
        },
        preloadImages: true,
        spaceBetween: 30
    });
}
;


/**
 * Ymaps
 */
if (document.querySelector("#contacts-map")) {
    const coords = document.querySelector("#contacts-map").dataset.coords.split(", "),
        apiKey = document.querySelector("#contacts-map").dataset.key;

    ymaps.load("https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=" + apiKey).then(maps => {
        const myMap = new maps.Map("contacts-map", {
                center: coords,
                zoom: 17,
                controls: ["fullscreenControl", "zoomControl"],
            }),
            myPlacemark = new maps.Placemark(
                myMap.getCenter()
                /*,
                {
                    hintContent: "Forward - Промышленные редукторы"
                },
                {
                    iconLayout: "default#image",
                    iconImageHref: "/wp-content/uploads/2021/09/group-66.svg",
                    iconImageSize: [84, 114],
                    iconImageOffset: [-42, -114]
                }*/
            );
        myMap.behaviors.disable("scrollZoom");
        myMap.geoObjects.add(myPlacemark);
    })
}

/* Расположение нумерованных элементов списка в колонках grid сетки сверху вниз(а не слева на право как по дефолту) */
let arrangeElementsOfGridNumericListFromTopToBottom = function (selector) {
    if (document.querySelector(selector)) {
        for (const numericList of document.querySelectorAll(selector)) {
            const listItems = numericList.querySelectorAll("li");

            if (listItems.length === 0 || window.getComputedStyle(numericList).gridTemplateColumns.split(" ").length === 1) break;
            const columnsAmount = window.getComputedStyle(numericList).gridTemplateColumns.split(" ").length;

            for (let i = listItems.length; i > 0;) {
                let thresholdColumn = listItems.length % columnsAmount;

                for (let j = columnsAmount, equalizer = 0; j > 0; j--) {
                    let orderNumber;

                    if (j === columnsAmount && thresholdColumn !== 0) {
                        orderNumber = listItems.length - (listItems.length % columnsAmount);
                        equalizer = (listItems.length % columnsAmount) + 1;

                    } else {
                        if (j === thresholdColumn) {
                            orderNumber = listItems.length;
                            equalizer = 0;
                        } else {
                            orderNumber = listItems.length - equalizer;
                            equalizer++;
                        }
                    }

                    while (orderNumber > 0) {
                        listItems[i - 1].style.order = orderNumber;
                        orderNumber -= columnsAmount;
                        i--;
                    }
                }
            }
        }
    }
};
arrangeElementsOfGridNumericListFromTopToBottom(".news__part ol");

if (document.querySelector('.folding-text__btn')) {
    document.querySelectorAll('.folding-text__btn').forEach((el) => {
        el.addEventListener('click', (e) => {
            if (!e.currentTarget.closest('.folding-text')) return;

            const foldingTextContainer = e.currentTarget.closest('.folding-text');

            const textHeight = getComputedStyle(foldingTextContainer.querySelector(".folding-text__text")).height;

            foldingTextContainer.classList.toggle('visible');

            e.currentTarget.querySelectorAll(".folding-text__btn-text").forEach((el) => {
                el.hidden = !el.hidden;
            })

            if (foldingTextContainer.classList.contains('visible')) {
                foldingTextContainer.querySelector(".folding-text__text-wrapper").style.height = textHeight;
            } else {
                foldingTextContainer.querySelector(".folding-text__text-wrapper").style.height = "0";
            }
        });
    });
}
